import React from "react"
import Layout from "../components/layout"
import ProjectsHeader from "../components/projects/projects-header"
import { useWPProjects } from "../hooks/useWPProjects"

export default function OurProjects() {
  const projects = useWPProjects()

  return (
    <Layout>
      <div className="bg-white max-w-7xl mx-auto ">
        <div className="md:flex flex-wrap justify-center">
          {projects.allWpProject.edges.map(project => (
            <ProjectsHeader data={project.node} key={project.node.id} />
          ))}
        </div>
      </div>
    </Layout>
  )
}
