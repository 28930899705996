import { graphql, useStaticQuery } from "gatsby"

export const useWPProjects = () => {
  return useStaticQuery(
    graphql`
      query getProjects {
        allWpProject(sort: { fields: projectContent___order }) {
          edges {
            node {
              id
              databaseId
              title
              link
              image {
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
}
