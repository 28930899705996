import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"

export default function ProjectsHeader({ data }) {
  const image = getImage(data.image.image.localFile)
  return (
    <>
      <div
        className="h-60
      md:w-1/2
      lg:h-80 lg:w-1/3 static text-white "
      >
        <a href={data.link}>
          <div className="relative cursor-pointer pointer-events-none">
            <div className="absolute flex w-full h-60 lg:h-80 items-center justify-center z-10">
              <p className="mx-2 font-semibold text-2xl lg:text-2xl drop-shadow-2xl glow text-center ">
                {data.title}
              </p>
            </div>
          </div>
          <div className="p-1 h-full">
            <div className="bg-green-700 h-full">
              <GatsbyImage
                image={image}
                alt={data.title}
                className="h-full filter hover:opacity-30"
              />
            </div>
          </div>
        </a>
      </div>
    </>
  )
}
